<template>
  <div class="container-fluid px-0 mx-0">
    <div class="row my-5 mx-0">
      <div class="col-8 offset-2 ">
        <div class="border-p py-5 px-5">
          <div class="px-5">
            <h3 class="text-left  color-black"><b> Te mostraremos un ejemplo </b></h3>
            <h4 class="text-left color-black"><b>por 3 dias</b></h4>
            <table class="table table-hover mt-5">
              <thead class="bg-blue-sky text-light">
                <tr>
                  <td class="text-left" scope="col">Precio / noche</td>
                  <td class="text-left" scope="col">Noches</td>
                  <td class="text-right" scope="col">Ingreso alquiler</td>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-left" ><span>80.00</span>$</th>
                  <th class="text-left">4</th>
                  <th class="text-right">680.00$</th>                  
                </tr>
                <tr>
                  <td class="text-left">Gestion de Linetor</td>
                  <td class="text-left">(18% IVA incluido)</td>
                  <th class="text-right">80.00$</th>                  
                </tr>
                <tr >
                  
                  <td class="text-right" colspan="3"> <span class="mr-5">Ingresos netos*</span> <span class="color-blue-sky h5"><b> 80.00$</b></span></td>                  
                </tr>
              </tbody>              
            </table>
            <p class="text-left color-black ">* La taza de limpieza es pagada por el huesped <span class=""></span></p>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
      return {        
        selected: 'first',
        options: [
          { text: 'Toggle this custom radio', value: 'first' },
          { text: 'Or toggle this other custom radio', value: 'second' },
          { text: 'This one is Disabled', value: 'three ' },          
        ],
        textos:{},
        pais: null,
        paises: [
          { value: null, text: 'Please select an option' },
          { value: 'a', text: 'This is First option' },
          { value: 'b', text: 'Selected Option' },
          { value: { C: '3PO' }, text: 'This is an option with object value' },
          { value: 'd', text: 'This one is disabled', disabled: true }
        ],
        idioma: null,
        idiomas: [
          { value: null, text: 'Please select an option' },
          { value: 'a', text: 'This is First option' },
          { value: 'b', text: 'Selected Option' },
          { value: { C: '3PO' }, text: 'This is an option with object value' },
          { value: 'd', text: 'This one is disabled', disabled: true }
        ],
        textos_pre: {
          // text_Empresa: 'Empresa',
          // text_Nombre: 'Nombre',
          // text_Apellido: 'Apellido',
          // text_Ciudad: 'Ciudad',
          // text_Telefono:'Telefono',
          // text_Email:'E-mail',
          // text_Peticion_presupuesto: 'Petición de presupuesto',
          // text_Button_ver_mas: 'Enviar',
        },
        form:{},
        show: true,
       
      }
    },
    async mounted(){
        this.$store.watch(
          (state) => [state.recarga].join(),
          async () => {
            // this.showItemsNumber();
            // this.refreshButton();
            this.textos = JSON.parse(localStorage.getItem('text'))
            await this.llenarCampos()
            console.log(this.textos)
          }
        );
        this.textos = JSON.parse(localStorage.getItem('text'))
        await this.llenarCampos()
      },
      methods:{
        onSubmit(){

        },
        onReset(){

        },
        async llenarCampos(){
          this.textos_pre={
          text_Empresa: this.textos.text_Empresa,
          text_Nombre: this.textos.text_Nombre,
          text_Apellido: this.textos.text_Apellido,
          text_Ciudad: this.textos.text_Ciudad,
          text_Telefono: this.textos.text_Telefono,
          text_Email: this.textos.text_Email,
          text_Peticion_presupuesto: this.textos.text_Peticion_presupuesto,
          text_Button_enviar: this.textos.text_Button_enviar,                         
        }
        console.log('llenando campos en presupuesto mounted', this.textos_pre)

        }
      }
}
</script>

<style>

.bg-img-pres {
  /* The image used */
  background-image: url("/img/img-bg-pre.png");

  /* Set a specific height */
  height: 460px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>